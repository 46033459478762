import React, { useCallback, useEffect, useMemo } from 'react';
import Button from '@material-ui/core/Button';
import {
  clearWorkstationAction,
  clearWorkstationsAction,
  revokeWorkstationAction,
} from '../../../api/auth';
import { withRouter } from 'react-router-dom';
import useStyles from '../../../hooks/useStyles';
import amplitude from '../../../util/amplitude';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';
import TableBody from '@material-ui/core/TableBody';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';

export default withRouter(function PaymentForm({
  licenses,
  history,
  match: { params },
}) {
  const classes = useStyles();

  const license = useMemo(
    () => licenses.find(license => license.id === params.licenseId),
    [licenses, params.licenseId]
  );

  const onCancel = useCallback(() => (window.location.href = '/dashboard'), [
    history,
  ]);
  const getForbiddenInfo = statusCode =>
    statusCode === 403
      ? '\n\nPlease contact us at support@simpolium.com to enable this feature to your account.'
      : '';

  const handleClearAction = () => {
    const allow = window.confirm(
      `Are you sure you want to clear workstations on this license? \n \nIf you click OK, you will be able to authenticate with new workstation(s).`
    );
    if (allow) {
      clearWorkstationsAction(params.licenseId).then(response => {
        alert(response.notification + getForbiddenInfo(response.statusCode));

        if (response.statusCode === 200) {
          onCancel();
        }
      });
    }
  };

  const handleApplyAction = (id, action) => {
    const moreInfo =
      action === 'DISABLE'
        ? 'After disabling, you can activate a new one without accidentally reactivating the same workstation.'
        : 'Deleting will allow activating again if workstation count is not full.';

    const allow = window.confirm(
      `Are you sure you want to ${action.toLowerCase()} this workstation?\n\n${moreInfo}`
    );

    if (allow && action === 'DISABLE') {
      revokeWorkstationAction(params.licenseId, id).then(response => {
        alert(response.notification + getForbiddenInfo(response.statusCode));

        if (response.statusCode === 200) {
          onCancel();
        }
      });
    } else if (allow && action === 'DELETE') {
      clearWorkstationAction(params.licenseId, id).then(response => {
        alert(response.notification + getForbiddenInfo(response.statusCode));

        if (response.statusCode === 200) {
          onCancel();
        }
      });
    }
  };

  useEffect(() => {
    amplitude.getInstance().logEvent('Open Manage License Form');
  }, []);

  if (!license) {
    return 'License was not found';
  }

  return (
    <>
      <Grid item xs={12}>
        <div className={classes.marginLeft}>
          <p>
            After activating this feature, please use it responsibly. <br />
            Excessive resets may lead to <i>Manage License</i>'s automatic
            deactivation.
          </p>
        </div>
      </Grid>
      <Grid item xs={12}>
        <div className={classes.marginLeft}>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => handleClearAction(license._id)}
          >
            Reset Workstations
          </Button>
          <Button
            variant="contained"
            color="default"
            style={{ marginLeft: '10px' }}
            onClick={() => onCancel()}
          >
            Close Window
          </Button>
        </div>
      </Grid>
      <TableContainer component={Paper}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Status</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {license.workstations
              .sort((prev, { createdAt }) => createdAt - prev.createdAt)
              .reverse()
              .map(workstation => (
                <TableRow key={workstation._id}>
                  <TableCell>
                    <b className="truncate-text">{workstation.name}</b>
                    <small className="truncate-text">
                      {btoa(workstation.id)}
                    </small>
                  </TableCell>
                  <TableCell>
                    {workstation.status} <br />
                    <small>
                      Add Date:{' '}
                      {moment(workstation.createdAt).format('DD/MM/YYYY')}
                    </small>
                  </TableCell>
                  <TableCell>
                    {workstation.status === 'GRANTED' && (
                      <Button
                        color="default"
                        size="small"
                        onClick={() =>
                          handleApplyAction(workstation._id, 'DISABLE')
                        }
                      >
                        DISABLE
                      </Button>
                    )}
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="secondary"
                      size="small"
                      onClick={() =>
                        handleApplyAction(workstation._id, 'DELETE')
                      }
                    >
                      DELETE
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
});
